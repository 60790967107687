/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~leaflet/dist/leaflet.css";

.facebook-btn {
  width: 40px;
  height: 40px;
  --border-radius: 10px;
  --background: #4267b2;
}

.instagram-btn {
  width: 40px;
  height: 40px;
  --background: transparent !important;
}

.red-icon {
  position: absolute;
  width: 25px;
  height: 25px;
}

.red-img {
  position: absolute;
  min-width: 45px;
  height: 45px;
}

.watermarked {
  top: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgb(199, 199, 199);
  //position: relative;
}

.watermarked:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("./assets/icon/favicon.png");
  background-size: 200px 200px;
  background-position: 30px 30px;
  //transform: rotate(45deg);
  background-repeat: space space;
  filter: grayscale(100%);
  //filter: blur(10px);
  opacity: 0.05;
}

/*
ion-content{
  position: absolute;
  --background: rgb(240, 240, 240) url("./assets/images/corporative/logo_sin_rect.png") repeat center center / contain;
   -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}
 */

.form-error {
  color: rgb(255, 153, 0);
  padding-left: 18px;
}

.modal {
  // color: white; // text color for basic alerts

 // --min-width: 330px;

  ion-col{
    text-align: center;
  }

  ion-icon{
    color: var(--ion-color-success) !important;
    font-size: 50px;
  }

  .alert-title.sc-ion-alert-md {
    color: #03277d;
  }

  [aria-checked="true"].alert-title.sc-ion-alert-md {
    border-color: #03277d;
  }

  .alert-radio-inner.sc-ion-alert-md{
    background-color: #03277d !important;
  }

  .alert-button-inner.sc-ion-alert-md {
    color: #03277d;
  }

  .alert-message {
   padding-left: 5px !important;
   padding-right: 5px !important;
   padding-top: 0 !important;
   padding-bottom: 0 !important;
  }


  /* button {
    color: white !important;
    background-color:
     #428cff !important;
  }
  ion-label{
    margin: auto !important;
  }
  .alert-message {
    //color: white; // text color for confirm alerts
    //text-align: center;
    margin: auto !important;
  }
  .alert-wrapper {
    //background-color: yellow;
    ion-label{
      margin: auto !important;
    }
  } */
}


.modal-privacy {

  //--width: 600px;

 /*  .modal-wrapper{
    transform: translate3d(0,0,0);//for fix ios 13.3
    background: transparent !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 00px;
    max-height: 170px;
    .ion-page {
      .modal-content {
        overflow: auto;
      }
    }
  } */
  // color: white; // text color for basic alerts


  ion-col{
    text-align: center;
  }

  ion-icon{
    color: var(--ion-color-success) !important;
    font-size: 50px;
  }

  .alert-title.sc-ion-alert-md {
    color: #03277d;
  }

  [aria-checked="true"].alert-title.sc-ion-alert-md {
    border-color: #03277d;
  }

  .alert-radio-inner.sc-ion-alert-md{
    background-color: #03277d !important;
  }

  .alert-button-inner.sc-ion-alert-md {
    color: #03277d;
  }
/*
  .alert-message {
   padding-left: 5px !important;
   padding-right: 5px !important;
   padding-top: 0 !important;
   padding-bottom: 0 !important;
  } */

}
